<template>
  <div class="videoDetail">
    <div class="back" @click="changeWarningDialog()">
      <img src="@/assets/images/back.png" />
      <div class="back-text">返回上一页</div>
    </div>
    <div class="videoDetail__search">
      <el-row>
        <el-col :span="12">
          <span>课程名称：</span>
          <el-input
            clearable
            style="width: 400px"
            placeholder="请输入"
            v-model="form.videoName"
          ></el-input>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button class="search-button" type="primary" @click="searchData"
            >搜索</el-button
          >
          <el-button class="search-button" type="primary" @click="toRelate"
            >关联视频课程</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="videoDetail__content">
      <div class="table-container">
        <el-table
          :data="tableData"
          :height="tableHeight - 200"
          style="width: 100%"
          border
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            prop="videoName"
            align="center"
            header-align="center"
            min-width="165"
            label="排序"
          >
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="videoName"
            align="center"
            header-align="center"
            min-width="165"
            label="视频课程名称"
          >
          </el-table-column>
          <el-table-column
            prop="typeName"
            align="center"
            header-align="center"
            min-width="165"
            label="分类"
          >
          </el-table-column>
          <el-table-column
            prop="price"
            align="center"
            header-align="center"
            min-width="165"
            label="单价"
          >
          </el-table-column>
          <el-table-column
            prop="publishStatus"
            align="center"
            header-align="center"
            min-width="165"
            label="状态"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.publishStatus === '1'">上架</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="videoName"
            align="center"
            header-align="center"
            min-width="165"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="openDeleteDialog(scope.row.relevanceId, 1)"
                >移除</el-button
              >
              <el-button
                type="text"
                @click="toMove(scope.row, scope.$index, 1)"
                v-if="scope.$index != 0"
                >上移</el-button
              >
              <el-button
                type="text"
                @click="toMove(scope.row, scope.$index, 2)"
                v-if="scope.$index + 1 != tableData.length"
                >下移</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 关联视频课程 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :videoList="videoList"
      :sceneClassList="selectIdList"
      @closeDialog="changeAddDialog"
      @openDialog="openDialog"
    ></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import mixin from "@/mixins/index";
import IndexClass from "./indexClass";
import apis from "@/apis";
import { mapState } from "vuex";
import confirmDialog from "@/components/confirmDialog.vue";
import addDialog from "./addDialog.vue";

export default {
  components: {
    confirmDialog,
    addDialog,
  },
  data() {
    return {
      addDialog: {
        visible: false,
      },
      form: new IndexClass("form"), // 实例化一个表单的变量
      tableData: [],
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      videoList: [],
      selectIdList: [],
    };
  },
  computed: {
    ...mapState(["Language", "venueId"]),
  },
  mixins: [mixin],
  methods: {
    toRelate() {
      this.addDialog.visible = true;
    },
    toMove(row, index, type) {
      if (type === 1) {
        const params = {
          relevanceId: row.relevanceId,
          relevanceNewId: this.tableData[index - 1].relevanceId,
        };
        this.$http.put(apis.updateVideoCourseSort, params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("上移成功!");
            this.searchData();
          }
        });
      } else {
        const params = {
          relevanceId: row.relevanceId,
          relevanceNewId: this.tableData[index + 1].relevanceId,
        };
        this.$http.put(apis.updateVideoCourseSort, params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("上移成功!");
            this.searchData();
          }
        });
      }
    },
    changeAddDialog() {
      this.searchData();
      this.addDialog.visible = false;
    },
    openDialog(data) {
      console.log(data);
    },
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type;
      this.confirmDialog.data.id = id;
      this.changeConfirmDialog(true, type);
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 1) {
        this.modelDelete();
      }
    },
    /**
     * 删除
     */
    modelDelete() {
      this.$http
        .delete(
          `${apis.deleteVideoCourseRelevance}?relevanceId=${this.confirmDialog.data.id}`,
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            this.$message.success("删除成功!");
            this.searchData();
          }
        });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.id = "";
        this.searchEvent();
      }
    },
    changeWarningDialog() {
      this.$router.go(-1);
    },
    searchData() {
      this.selectIdList = [];
      this.$http.post(apis.findVideoCourseByCourseId, this.form).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.tableData = res.data.data;
          if (this.tableData.length > 0) {
            this.tableData.forEach((item) => {
              this.selectIdList.push(item.videoCourseId);
            });
          }
          //   this.form.total = res.data.total;
        }
      });
    },
    selectAllType() {
      this.$http.post(apis.selectAllType).then((res) => {
        if (res.data.code === 0) {
          this.videoList = res.data.data;
          // this.tableData = res.data.rows;
          // this.form.total = res.data.total;
        }
      });
    },
  },
  created() {
    console.log(this.$route.query);
    console.log(this.tableHeight);
  },
  activated() {
    this.form.trainingCourseId = this.$route.query.id;
    this.selectAllType();
  },
};
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.videoDetail {
  padding: 20px;
  .back {
    margin-top: 14px;
    margin-bottom: 10px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      display: inline-block;
    }
    .back-text {
      margin-left: 10px;
      // margin-bottom: 5px;
      font-weight: 400;
      color: rgba(67, 155, 255, 1);
      vertical-align: middle;
      display: inline-block;
    }
  }
  &__search {
  }
  &__content {
    margin-top: 20px;
  }
}
</style>
