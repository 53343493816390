import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
    constructor(type) {
        super()
        if (type === 'form') {
            this.videoName = ''
            this.typeId = ''
            this.publishStatus = '1'
        }
    }
}
export default searchFrom
