<template>
  <div class="dialog-container">
    <el-dialog
      title="关联课程视频"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%"
    >
      <div class="content">
        <el-row>
          <el-col :span="9" style="display: flex; align-items: center">
            <div style="width: 100px">课程名称</div>
            <el-input
              clearable
              v-model="form.videoName"
              style="width：100px"
              placeholder="请输入视频课程名称"
            ></el-input>
          </el-col>
          <el-col
            :span="9"
            style="display: flex; align-items: center; margin-left: 10px"
          >
            <div style="width: 100px">视频分类</div>
            <el-select v-model="form.typeId" style="width: 100%">
              <el-option
                v-for="item in videoList"
                :key="item.id"
                :label="item.typeName"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- <el-input style="width：150px"></el-input> -->
          </el-col>
          <el-col :span="3" style="text-align: right">
            <el-button class="search-button" type="primary" @click="searchData"
              >搜索</el-button
            >
          </el-col>
        </el-row>
        <div class="table-container">
          <el-table
            :row-key="getRowKeys"
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight - 300"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
            @selection-change="handleSelectionChange"
          >
            >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              prop="videoName"
              align="center"
              header-align="center"
              min-width="165"
              label="课程名称"
            >
            </el-table-column>
            <el-table-column
              prop="typeName"
              align="center"
              header-align="center"
              min-width="165"
              label="分类"
            >
            </el-table-column>
            <el-table-column
              prop="price"
              align="center"
              header-align="center"
              min-width="165"
              label="价格"
            >
            </el-table-column>
            <el-table-column
              prop="publishStatus"
              align="center"
              header-align="center"
              min-width="165"
              label="状态"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.publishStatus === 1">上架</span>
                <span v-else>下架</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total"
          >
          </el-pagination>
        </div>
      </div>
      <template slot="footer">
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <!-- <div class="footer-confirm" @click="onSave">{{ $t("sure") }}</div> -->
        <el-button class="footer-cancel" @click="closeDialog">取消</el-button>
        <el-button class="footer-confirm" @click="sureEvent">确定</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import mixin1 from "@/mixins/index";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin, mixin1],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    videoList: {
      type: Array,
    },
    sceneClassList: {
      type: Array,
    },
  },
  watch: {
    sceneClassList: {
      handler(newValue) {
        this.multipleSelection = newValue;
      },
    },
  },
  mounted() {
    console.log("DROPDOWNBOX.VENUE_SERVICE", this.DROPDOWNBOX);
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      tableData: [],
      showPic: true,
      multipleSelection: [],
      currentList: [],
      flag: false,
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList", "venueId"]),
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    async handleCurrentChange(pageNum) {
      this.form.pageNum = pageNum;
      this.searchData();
    },
    handleSelectionChange(val) {
      this.currentList = [];
      if (val.length > 0) {
        val.forEach((item) => {
          this.currentList.push(item.id);
        });
      }
    },
    setData() {
      console.log(this.multipleSelection);
      this.$nextTick(() => {
        const tableData = this.tableData;
        const userIdSelectList = this.multipleSelection;
        if (tableData.length > 0) {
          userIdSelectList.forEach((item) => {
            tableData.forEach((newItem, index) => {
              if (item === newItem.id) {
                this.$refs.multipleTable.toggleRowSelection(
                  tableData[index],
                  true,
                );
              }
            });
          });
        }
        // this.$refs.multipleTable.toggleAllSelection();
      });
      //   this.tableData.forEach((item, index) => {
      //     this.multipleSelection.forEach((newItem) => {
      //       if (item.id === newItem) {
      //         this.$refs.multipleTable.toggleRowSelection(
      //           this.tableData[index],
      //           true,
      //         );
      //       }
      //     });
      //   });
    },
    // 查询
    searchData() {
      const formData = { ...this.form };
      this.$http.post(apis.selectVideoCoures, formData).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
          this.setData();
          // if (this.flag) {
          //   this.setData();
          // }
        }
      });
    },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init();
      this.searchData();
      this.$refs.multipleTable.clearSelection(); //清除选中的数据
      // this.$refs.bMap.clearData();
      this.$emit("closeDialog", false);
    },
    openDialog() {
      this.setData();
      // if (!this.$refs.multipleTable) {
      //   setTimeout(() => {
      //     this.setData();
      //   }, 200);
      // } else {
      //   this.setData();
      // }
      //   console.log(11111111);
      //   this.$emit("openDialog", this.tableData);
    },
    sureEvent() {
      console.log(1111111);
      const formData = {
        courseId: this.$parent.form.trainingCourseId,
        videoCourseIds: this.currentList,
      };
      this.$http
        .post(apis.addOrUpdateVideoCourseRelevanceCourse, formData)
        .then((res) => {
          if (res.data.code === 0) {
            this.closeDialog();
            //   this.tableData = res.data.rows;
            //   this.form.total = res.data.total;
          }
        });
      //   addOrUpdateVideoCourseRelevanceCourse;
      //   console.log(this.$parent.form.trainingCourseId);
    },
    ...mapMutations(["setVenueId", "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      //   this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
  },
};
</script>
<style lang="less" scoped>
.dialog-container {
  .content {
    // display: flex;
    // justify-content: center;
    height: 400px;
  }
  .table-container {
    margin-top: 30px;
  }
  .page-container {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
